jQuery( document ).ready(function( $ ) {


 //$('.menu').dropit();


 //$( '.swipebox' ).swipebox();

 $('.mh-item').matchHeight();


  $('.navMain').dropit();














});